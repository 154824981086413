import { FC } from "react"
import Text from 'antd/es/typography/Text'
import ActionModalComponent from "modules/kyc-front/user-actions/ActionModalComponent"

type DProps = {
	open: boolean,
	handleOk: (value: any) => void,
	handleCancel: (value: any) => void
}

const DeleteClientModal: FC<DProps> = ({open, handleOk, handleCancel}) => (
	<ActionModalComponent
		open={open}
		handleOk={handleOk}
        name='Deleting client'
		okText='Yes, delete'
		handleCancel={handleCancel}
		cancelText='No, cancel'
	>
        <Text>Are you sure you want to delete this client?</Text>
        <br />
        <Text>This action is irreversible</Text>
  </ActionModalComponent>
)

export default DeleteClientModal