import { FC, useEffect, useRef, useState } from "react"
import Text from 'antd/es/typography/Text'
import TextArea from 'antd/es/input/TextArea'
import { FormCheckboxRadioFormStandalone } from "modules/kyc-front/ui-elements/FormCheckboxRadio"
import styled from "styled-components"
import ActionModalComponent from "modules/kyc-front/user-actions/ActionModalComponent"

type DProps = {
	open: boolean,
	handleOk: (value: any) => void,
	handleCancel: (value: any) => void
}

const FormCheckboxRadioCustom = styled.div`
    margin-bottom: 10px;
    .checkContainer .ant-col {
        margin-bottom: 10px;
    }
`


const defaultText = `Thank you for submitting your KYC application to Bitclear.

After careful review of your application, we regret to inform you that we are unable to proceed with the verification process at this time. While we appreciate your interest in our services, we must maintain strict compliance with our regulatory obligations and internal risk management policies.

We appreciate your understanding in this matter and thank you for your interest in Bitclear.`

const RejectKYCModal: FC<DProps> = ({open, handleOk, handleCancel}) => {
    const [description, setDescription] = useState(defaultText);
    const [shouldSendEmail, setShouldSendEmail] = useState(false);
    const [height, setHeight] = useState(0);
    const [opacity, setOpacity] = useState<0 | 1>(0);
    const contentRef = useRef<HTMLDivElement | null>(null);

    const handleDescriptionChange = (el: any) => {
        setDescription(el.target.value);
    };

    const handleToggle = () => {
        const newShouldSendEmail = !shouldSendEmail;
        setShouldSendEmail(newShouldSendEmail);
        
        if (contentRef.current) {
            setHeight(newShouldSendEmail ? contentRef.current.scrollHeight : 0); 
        }
        setOpacity(newShouldSendEmail ? 1 : 0); 
    };

    useEffect(() => {
        if (contentRef.current) {
            setHeight(shouldSendEmail ? contentRef.current.scrollHeight : 0);
        }
    }, [shouldSendEmail]);
    return (
        <ActionModalComponent
            open={open}
            handleOk={() => handleOk({shouldSendEmail, wording: description})}
            name='Rejecting KYC'
            okText='Reject KYC'
            handleCancel={handleCancel}
        >
                <Text>Rejected client will not be able to log in anymore.</Text>
                <br />
                <Text>The record of this client will be kept as a closed account.</Text>
                <br />
                <br />
                <FormCheckboxRadioCustom>
                    <FormCheckboxRadioFormStandalone label='' id={'shouldSendEmail'} type='radio' onChange={handleToggle}
                        options={[{
                                label: 'Send email to the client with following information',
                                value: 'true'
                            },
                            {
                                label: 'Do not inform the client',
                                value: 'false'
                            }
                        ]
                    } value={shouldSendEmail.toString()} />
                </FormCheckboxRadioCustom>
                <div
                    ref={contentRef}
                    style={{
                        overflow: 'hidden',
                        transition: 'height 0.5s ease, opacity 0.5s ease',
                        height: `${height}px`,
                        opacity: opacity,
                }}>
                    <TextArea style={{resize: "none", marginTop: 10, marginBottom: 15}} onChange={handleDescriptionChange} rows={10} value={description}></TextArea>
                    <br />
                </div>
        </ActionModalComponent>
    )
}

export default RejectKYCModal