import { Space, Row, Col, message, Popconfirm, Modal } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useKycApprove } from '../../../hooks/useKycApprove'
import { dispatch } from '../../../store/store'
import { setEditedFormConfirmed, setIsKycEdited } from '../../../store/kyc-front/slices/navSlice'
import { individualArchive } from '../../../store/kyc-backend/asyncThunks/individualArchive'
import { companyArchive } from '../../../store/kyc-backend/asyncThunks/companyArchive'
import { companyProfileBackGet } from '../../../store/kyc-backend/asyncThunks/companyProfileBackGet'
import { individualProfileBackGet } from '../../../store/kyc-backend/asyncThunks/individualProfileBackGet'
import { themeColor } from '../../../styles/themeStyles'
import { useSelector } from '../../../hooks/useSelector'
import { ButtonUpper } from '../ui-elements/Buttons'
import { ColRight } from '../ui-elements/Columns'
import { useActionStatus } from '../../../hooks/useActionStatus'

import { kycApplicationFinalApprove } from 'store/kyc-backend/asyncThunks/kycApplicationFinalApprove'
import { kycApplicationApprovalsGet } from 'store/kyc-backend/asyncThunks/kycApplicationApprovalsGet'
import { kycApplicationApprovalsUpdate } from 'store/kyc-backend/asyncThunks/kycApplicationApprovalsUpdate'
import { kycApplicationApprovalsUpdateClear } from 'store/kyc-backend/slices/backKycApplication'
import { kycApplicationValidate } from 'store/kyc-front/asyncThunks/kycApplicationValidate'
import Well from '../ui-elements/Well'
import { Link } from 'react-router-dom'
import { NavItem } from './KycLayout'

const NavSpace = styled(Space)`
	background-color: #fff;
	padding: 15px 30px;
	margin-left: -30px;
	margin-right: -30px;
`
const ButtonUpperSuccess = styled(ButtonUpper)`
	&:not([DISABLED]){
		border-color: ${themeColor.green};
		color: ${themeColor.green};
		&.ant-btn&:not(.ant-btn-primary) {
			&:hover, &:focus, &:active {
				border-color: ${themeColor.green};
				color: ${themeColor.green};
			}
		}
	}
`
interface Approval {
	ref: string;
	slug: string;
	isApprovalRequirementSatisfied: boolean;
	approvedBy: string[];
}

interface Document {
	id: string;
	approvals: Approval[];
}
  
interface DocResponse {
	documents: Document[];
}

interface ResultDocument {
	id: string;
	kind: boolean;
}
  
interface Result {
	documents: ResultDocument[];
}

interface IProps {
	approve?: boolean;
	archive?: boolean;
	restore?: boolean;
	validationAction?: (value: any) => any;
	validationActionParams?: object;
	hideValidate?: boolean;
	hideSaveButton?: boolean;
	hideApproveStepButton?: boolean;
	setErrorsListing?: (value: any) => any;
}

type SProps = {
	open: boolean,
	handleOk: (value: any) => void,
	handleCancel: (value: any) => void
}

const InvalidFormAdminModal: FC<SProps> = ({open, handleOk, handleCancel}) => {
	return (
		<Modal
			title="This form is invalid"
			open={open}
			onOk={handleOk}
			okText='Approve anyway'
			onCancel={handleCancel}
		>
			<br />
			<p>There are some fields in this application that are invalid. Do you want to proceed with approving this application?</p>
			<p>You can either approve application, or cancel end edit missing values or send back to client.</p>
			<br />
      </Modal>
	)
}

export const KycNav: FC<IProps> = ({approve, archive, restore, validationAction, validationActionParams, hideValidate, hideSaveButton, hideApproveStepButton, setErrorsListing}) => {
	const nav = useSelector((state) => state.general.nav);
	const envs = useSelector((state) => state.general.envs);
	const { validation, validationFullResponse, stepsScheme, kycApplication } = useSelector((state) => state.user2.kycApplication)
	const { disabledApprovalMsg, isApprovalDisabled, isKycRejected } = useKycApprove()
	const validationFullResponseValues = validationFullResponse.payload?.validationErrors
	const { multiApprove, kycApplicationApprovals } = useSelector((state) => state.admin2.applicationApprovals);
	const { approvalErrors } = useSelector((state) => state.admin2.approvalErrors);
	let [popupArchiveVisible, setPopupArchiveVisible] = useState(false);
	let {next, nextName, prev, prevName, step} = nav.nav;
	let {formUpdated, isKycEdited} = nav;
	let [disabledApprove, setDisabledApprove] = useState(false);
	let [approveKycClicked, setApproveKycClicked] = useState(false)
	let [adminEditing, setAdminEditing] = useState(false)
	let [messageShown, setMessageShown] = useState(false);
	let [archiveMessageShown, setArchiveMessageShown] = useState(false);
	let [restoreMessageShown, setRestoreMessageShown] = useState(false);
	const currentStep = nav.nav.step ?? 0;
	const apIndex = stepsScheme.findIndex(el => el.type === 'authorizedPersons');
	const apStep = stepsScheme[apIndex]?.step;
	const uboIndex = stepsScheme.findIndex(el => el.type === 'beneficialOwners');
	const uboStep = stepsScheme[uboIndex]?.step;
	const isApOrUbo = currentStep === apStep || currentStep === uboStep
	let el = useLocation()
	let apUboID = el.pathname.split('/')[envs.admin ? 5 : 4]
    //@ts-ignore
    const {isFullfilled, isCleared} = useActionStatus(validationAction);
	const approvalStatusUpdate = useActionStatus(kycApplicationApprovalsUpdate);
    const archivingStatus = useActionStatus(envs.isBusiness ? companyArchive : individualArchive);
	const finalApproveStatus = useActionStatus(kycApplicationFinalApprove)
    useEffect(() => {
		if(archivingStatus.isFullfilled && !archivingStatus.isCleared) {
			if(archiveMessageShown || restoreMessageShown) {
				if(restoreMessageShown) {
					message.success('Profile was successfully restored');
					setRestoreMessageShown(false);
				}
				if(archiveMessageShown) {
					message.success('Profile was successfully archived');
					setArchiveMessageShown(false);
				}
				if(envs.isBusiness) {
					dispatch(companyProfileBackGet({params: {companyId: envs.profileId}}))
				}
				if(envs.isPrivate) {
					dispatch(individualProfileBackGet({params: {individualId: envs.profileId}}))
				}
			}
			
		}
    }, [archivingStatus]);
    useEffect(() => {
		if(isFullfilled && !isCleared) {
			let substepErrors = validation?.find((err => err.refId === nav.nav.refId && err.substep === nav.nav.substep));
			handleValidationMessageShow(isApOrUbo ? substepErrors?.validationErrors  : validation?.[currentStep - 1]?.validationErrors);
			if(approveKycClicked) {
				setApproveKycClicked(false)
				const { wholeFormValid, approveFormValid } = checkValidityOfKYC()
				if(!wholeFormValid) {
					if(approveFormValid) {
						if(envs.admin) {
							setAdminEditing(true)
						}
					}
					if(setErrorsListing) {
						dispatch(kycApplicationApprovalsGet({ params: { id: envs.profileId } }))
						setErrorsListing(true)
					}
				} else {
					confirmApproval()
				}
			}
		}
    }, [validation]);
	useEffect(() => {
		const {wholeFormValid} = checkValidityOfKYC();
		if(wholeFormValid) {
			setDisabledApprove(false)
		}
	}, [validation, approvalErrors, validationFullResponseValues ])
	useEffect(() => {
		if(kycApplication.status === 'ACCEPTED' || isKycRejected) {
			setDisabledApprove(true)
		}
	}, [kycApplication])

	useEffect(() => {
        if(approvalStatusUpdate.isError || approvalStatusUpdate.isFullfilled) {
            if(multiApprove) {
                if(approvalStatusUpdate.isError) {
                    message.error('Some error occurred while updating this element');
                }
                if(isFullfilled) {
                    message.success('This element was successfully approved');
                }
                dispatch(kycApplicationApprovalsUpdateClear({}))
                dispatch(kycApplicationApprovalsGet({params: {id: envs.profileId}}))
            }
        }
    }, [approvalStatusUpdate]);
    const confirmApproval = () => {
		dispatch(kycApplicationFinalApprove({params: {id: envs.profileId}}))
		setAdminEditing(false)
	}
	const cancelApproval = () => {
		dispatch(setIsKycEdited(true))
		setAdminEditing(false)
	}
	const handleValidationMessageShow = (el: any, isArray?: boolean) => {
		if(isArray) {
			let isValid = true;
			el.forEach((elemInner: any) => {
				if(elemInner.validationErrors.length) {
					isValid = false
					return
				}
			});
			if(isValid && messageShown) {
				message.success('Validation completed! No errors found');
				setMessageShown(false);
			}
		} else {
			if(el && el.length === 0 && messageShown) {
				message.success('Validation completed! No errors found');
				setMessageShown(false)
			}
		}
		
	}
	const confirm = (type: string) => {
		if(prev && type === 'prev') {
			dispatch(setEditedFormConfirmed({onConfirm: true, onConfirmLink: prev, showErrors: false, refId: nav.nav.refId}));
		}
		if(next && type === 'next') {
			dispatch(setEditedFormConfirmed({onConfirm: true, onConfirmLink: next, showErrors: false, refId: nav.nav.refId}));
		}
	  	if(type === 'save') {
			dispatch(setEditedFormConfirmed({onConfirm: true, showErrors: true, refId: nav.nav.refId}));
		}
	}
	const approveStep = () => {
		const stepScheme = stepsScheme.find(el => el.link === nav.nav.link)
		if(stepScheme) {
			let approvalNames = getApprovalNames(stepScheme, currentStep === apStep ? 'authorizedPersons' : currentStep === uboStep ? 'beneficialOwners' : undefined)
			//@ts-ignore
			dispatch(kycApplicationApprovalsUpdate({params: {id: envs.profileId, multiApprove: true}, data: approvalNames}))
		}
	}
	const findDocumentsByName = (names: string[], response: DocResponse) => {
		const result: Result = { documents: [] };
		names.forEach((name) => {
			const slug = name.split('.')
		  	const slugToFind = name.split('.')[1]
		  	response?.documents?.forEach((document) => {
			const approvalMatch = document.approvals.some((approval) => approval.slug === slugToFind);
				if (approvalMatch) {
				result.documents.push({
					id: document.id,
					kind: true
				});
				}
		  	});
		});
		return result;
	}
	const getApprovalNames = (schemaParent: any, schemaName?: 'authorizedPersons' | 'beneficialOwners') => {
		let nameEl: any;
		let documentsApprovals;
		let isSignatory = false;
		const personEl = schemaName === 'authorizedPersons' ? kycApplicationApprovals.authorizedPersons.find((el: any) => el.id === apUboID) : schemaName === 'beneficialOwners' ? kycApplicationApprovals.beneficialOwners.find((el: any) => el.id === apUboID) : kycApplicationApprovals;
		if(schemaParent) {
			type NestedObject = {
				[key: string]: true | NestedObject;
			};
			const parsedSchema: NestedObject = {}
			documentsApprovals = findDocumentsByName(schemaParent.fields.filter((el: string) => el.includes('docs')), personEl)
			schemaParent.fields.forEach((el: string) => {
				let splitEl = el?.split('.');
				if (splitEl.length > 1) {
				  	let current: any = parsedSchema;
				  	if(splitEl[0] !== 'docs') {
						splitEl.forEach((part, index) => {
							if (index === splitEl.length - 1) {
							current[part] = index === 1 && splitEl.length === 3 ? { id: splitEl[1], [splitEl[2]]: true } : true;
							} else {
							if (!current[part]) {
								current[part] = {};
							}
							current = current[part];
							}
						});
					}
				} else {
				  if (el === 'sourceOfWealthBusinessActivities') {
					Object.assign(parsedSchema, {
					  sourceOfWealthBusinessActivities: true,
					  sourceOfWealthInheritance: true,
					  sourceOfWealthProfessionalOccupation: true,
					  sourceOfWealthOther: true,
					});
				  } else {
					if(el === 'isSignatory') {
						if(isSignatory === false) {
							isSignatory = true
						}
                        const signatoriesList = kycApplication.values.authorizedPersons
                        nameEl = {authorizedPersons: []};
                        signatoriesList?.forEach((el: any) => nameEl.authorizedPersons.push({id: el.id, isSignatory: true}))
                    } else {
						parsedSchema[el] = true;
					}
				  }
				}
			})
            if(schemaName) {
                nameEl = {[schemaName]: [{id: apUboID, ...parsedSchema}]}
            } else {
				if(!isSignatory) {
                	nameEl = parsedSchema
				}
            }
            
        } else {
            if(schemaName) {
                nameEl = {[schemaName]: true}
            }
			
            
        }
		return {...nameEl, ...documentsApprovals}
	}
    const cancelArchive = () => {
        setPopupArchiveVisible(false);
    }
    const confirmArchive = () => {
        setPopupArchiveVisible(false);
        setArchiveMessageShown(true);
        if(envs.isBusiness) {
        	dispatch(companyArchive({ params: {companyId: envs.profileId}, data: {archive: true}}))
        }
        if(envs.isPrivate) {
        	dispatch(individualArchive({ params: {individualId: envs.profileId}, data: {archive: true}}))
        }
    }
    const confirmRestore = () => {
    	setPopupArchiveVisible(false);
    	setRestoreMessageShown(true);
        if(envs.isBusiness) {
        	dispatch(companyArchive({ params: {companyId: envs.profileId}, data: {archive: false}}))
        }
        if(envs.isPrivate) {
        	dispatch(individualArchive({ params: {individualId: envs.profileId}, data: {archive: false}}))
        }
    }
    const handleApprove = () => {
		if(envs.admin) {
			setDisabledApprove(true)
			dispatch(kycApplicationValidate({params: {id: envs.profileId}}))
			setApproveKycClicked(true)
		}
	}
	const checkValidityOfKYC = () => {
		const validationErrors = validation.find(err => err.validationErrors.length)
		const approvingErrors = approvalErrors.find((step: any) => step.validationErrors.length)
		const approvingDocsErrors = approvalErrors.find((err: any) => err.docErrors.length)
		const directorError = validationFullResponseValues?.find((err: any) => err.ref === 'authorizedPersons' && err.slug === 'Director')
		const beneficialOwnerError = validationFullResponseValues?.find((err: any) => err.ref === 'beneficialOwners' && err.slug === 'required')
		const signatoryError = validationFullResponseValues?.find((err: any) => err.ref === 'authorizedPersons' && err.slug === 'signatories') 
		let userFormValid = false
		let approveFormValid = false
		let wholeFormValid = false
		if((approvingErrors || approvingDocsErrors) && envs.admin) {
			approveFormValid = false
		} else {
			approveFormValid = true
		}
		if(validationErrors || directorError || beneficialOwnerError || signatoryError ) {
			userFormValid = false
		} else {
			userFormValid = true
		}
		if(validationErrors || directorError || beneficialOwnerError || signatoryError || (envs.admin && (finalApproveStatus.isError || approvingErrors || approvingDocsErrors) )) {
			wholeFormValid = false
		} else {
			wholeFormValid = true
		}
		return {userFormValid, wholeFormValid, approveFormValid}
	}
	const status = kycApplication.status
	return (
		<>
			{(status === 'ACCEPTED') && step === 0 ? (
				<Row>
					<Col md={24}>
						<Well>
							<b>If your details change, you can make edits to KYC.</b>
							<p>Please note that profile with changes needs to be resubmitted and then approved by Bitclear AG.</p>
							<Row justify="end">
								<Link to={envs.admin ? envs.routeSuffix + '/' + NavItem.CLIENT_PROFILES + '/' + envs.profileId + next : envs.routeSuffix + '/profile' + next}>
									<ButtonUpper btnType="primary" ghost={true} onClick={() => dispatch(setIsKycEdited(true))}>Edit KYC</ButtonUpper>
								</Link>
							</Row>
						</Well>
					</Col>
				</Row>
			) : (
				<div className='content' style={{ 'borderTop': `2px solid ${themeColor.grayWhite}`, background: '#fff' }}>
					<NavSpace direction='vertical' size={'small'}>
						<Row>
							<Col md={12}>
								{prev &&
									<>
										<ButtonUpper onClick={() => confirm('prev')} btnType="primary" ghost>{prevName || 'Back'}</ButtonUpper>
									</>
								}
							</Col>
							<ColRight>
								<>
									{archive &&
										<Popconfirm
											title="Are You sure you want to archive this KYC profile?"
											onConfirm={confirmArchive}
											onCancel={cancelArchive}
											okText="Archive KYC"
											cancelText="Cancel"
											icon={<QuestionCircleOutlined />}
										>
											<ButtonUpper btnType="primary" className='left' ghost>Archive KYC</ButtonUpper>
										</Popconfirm>
									}
									{restore &&
										<Popconfirm
											title="Are You sure you want to restore this KYC profile?"
											onConfirm={confirmRestore}
											onCancel={cancelArchive}
											okText="Restore KYC"
											cancelText="Cancel"
											icon={<QuestionCircleOutlined />}
										>
											<ButtonUpper btnType="primary" className='left' ghost>Restore KYC</ButtonUpper>
										</Popconfirm>
									}
									{envs.admin && step && step > 0 && hideApproveStepButton !== true ?
										<span style={{ marginRight: 20 }}>
											<ButtonUpperSuccess btnType='default' onClick={() => approveStep()} disabled={isApprovalDisabled} title={disabledApprovalMsg}>Approve</ButtonUpperSuccess>
										</span>
									: <></>}
									{step && step > 0 && hideSaveButton !== true ?
										<span style={{ marginRight: 20 }}>
											<ButtonUpper btnType="primary" onClick={() => confirm('save')} disabled={!formUpdated.updated}>Save</ButtonUpper>
										</span>
									: <></>}
									{next &&
										<>
											<ButtonUpper onClick={() => confirm('next')} btnType="primary" ghost>{nextName || 'Next'}</ButtonUpper>

										</>
									}
									{approve &&
										<ButtonUpper btnType="primary" onClick={handleApprove} disabled={isApprovalDisabled ? true : isKycEdited ? false : disabledApprove} title={disabledApprovalMsg}>Approve KYC</ButtonUpper>
									}
								</>
							</ColRight>
						</Row>
					</NavSpace>
				</div>
			)}
			<InvalidFormAdminModal open={adminEditing} handleOk={confirmApproval} handleCancel={cancelApproval} />
		</>
	)
}
			
export default KycNav
