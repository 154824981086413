import { createAsyncThunk } from "@reduxjs/toolkit"
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "../../store"
import { AsyncThunkDataType } from "../../common"
import { Components, Paths } from "api/kyc/generated/client2"

type PathParameters = Paths.DropKycChanges.PathParameters

type ProfileResponse = Paths.DropKycChanges.Responses.$204


export const kycApplicationChangesDiscard: AsyncThunkActionType<
  ProfileResponse,
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("kycApplicationChangesDiscard", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    let response = await api2.dropKycChanges(data.params, null, data?.config);
    return response.data as ProfileResponse;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});