import React, { FC, useState, useEffect } from 'react'
import MyDatePicker from '../../../utilities/MyDatePicker'
import { useSelector } from '../../../hooks/useSelector'
import momentDate, { Moment } from 'moment-timezone'
import momentDateNew, { Moment as MomentNew } from 'moment-timezone'
import {  Row, Col } from 'antd'
import { themeColor } from '../../../styles/themeStyles'
import styled from 'styled-components'
import ApproveInput from "../../kyc-backend/ui-elements/Approve"
import EditedInputInfo from './EditedInput'

export const TimeZoneNew = 'Europe/Berlin'
export const TimeZone: number = 2

//const dateFormatList = ['YYYY-MM-DD', 'DD.MM.YYYY'];
const defaultDateFormat = 'DD MMM YYYY'
const defaultDateFormatDetailed = 'DD MMM YYYY HH:mm:ss'
export const dateFormatDetailed = 'YYYY-MM-DD HH:mm:ss'
const dateFormatList = ['YYYY-MM-DD', 'DD.MM.YYYY']

export const momentNew = ({ time, format, detailed }: { time: any, format?: string, detailed?: boolean }) => format ? momentDateNew(time).utc().tz(TimeZoneNew).format(format) : detailed ? momentDateNew(time).utc().tz(TimeZoneNew).format(defaultDateFormatDetailed) : momentDateNew(time).utc().tz(TimeZoneNew).format(defaultDateFormat)

export const moment = (time: any, format?: string) => format ? momentDate(time, format).utcOffset(TimeZone) : momentDate(time).utcOffset(TimeZone)

const CustomDatePicker = styled(MyDatePicker)`
    width: 100%;
    height: 40px;
    &.error {
        box-shadow: 0 0 2px 1px ${themeColor.red};
    }
    &.edited {
        background-color: ${themeColor.orangeLight};
        &:hover, &:focus, &:active, &:focus-within {
            border-color: ${themeColor.orangeWarning};
        }
        &:focus, &:focus-within {
            box-shadow: 0 0 0 2px ${themeColor.orangeLight};
        }
    }
    &.ant-picker-disabled {
        background-color: ${themeColor.grayWhite};
        color: ${themeColor.gray};
    }
`
const ErrorContainer = styled.div`
    margin-top: 3px;
    color: red;
`
interface DProps {
    label: string,
    id:  string,
    error?: string,
    type: 'authorized-persons' | 'ubos' | 'individual' | 'company',
    defaultPickerValue?: Moment,
    defaultValue?: Moment,
    disabled?: boolean,
    onChange: (value: any) => void,
    onSwitchChange?: (value: any) => void,
    showErrors?: boolean,
    schemaParent?: string,
    schemaName?: string,
    hideApprove?: boolean,
    personId?: string
}
const FormDate: FC<DProps> = ({
    label, id, error, defaultPickerValue, defaultValue, showErrors, disabled, onChange, onSwitchChange, schemaParent, schemaName, type, hideApprove, personId
}) => {
    const [isError, setIsError] = useState(false);
    const envs = useSelector((state) => state.general.envs)
    const onFocusOut = (el: any) => {
        if(el.target.value !== '') {
            setIsError(false)
        } else {
            if(isError === false && error?.length) {
                setIsError(true)
            }
        }
    }
    useEffect(() => {
        if(error?.length) {
            if(isError !== true) {
                setIsError(true)
            }
        } else {
            if(isError !== false) {
                setIsError(false)
            }
        } 
        
    }, [error])
    const edited = false
    return (
        <>
            <label className='ant-form-item-label' htmlFor={id} style={{'width': '100%'}}>
                <Row justify="space-between">
                    <Col>{label}</Col>
                    <Row>
                        {envs.admin && !hideApprove &&
                            <ApproveInput onSwitchChange={onSwitchChange} name={id} schemaParent={schemaParent} schemaName={schemaName} personId={personId} type={type}/>
                        }
                        <EditedInputInfo name={id} type={type}/>
                    </Row>
                </Row>
            </label>
            <CustomDatePicker format={dateFormatList} className={isError ? "error" : edited ? "edited" : ""} allowClear={false} name={id} onChange={onChange} onBlur={onFocusOut} defaultValue={defaultValue} defaultPickerValue={defaultValue ? defaultValue : defaultPickerValue} disabled={disabled} />
            {showErrors !== false &&
                <ErrorContainer>
                    {isError ? (
                        <div>{error}</div>
                    ) : null}
                </ErrorContainer>
            }
  		</>
			 
	)
}

export default FormDate
