import { createAsyncThunk } from "@reduxjs/toolkit";
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc/generated/client2";

type ProfileResponse = Components.Schemas.ProfileIdentity;

export const identityGet: AsyncThunkActionType<
  ProfileResponse,
  AsyncThunkDataType<null, null>
> = createAsyncThunk("identityGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api2.getProfile();
    return response.data as ProfileResponse;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
