import { FC } from "react"
import Text from 'antd/es/typography/Text'
import ActionModalComponent from "./ActionModalComponent"

type DProps = {
	open: boolean,
	handleOk: (value: any) => void,
	handleCancel: (value: any) => void
}

const DiscardChangesModal: FC<DProps> = ({open, handleOk, handleCancel}) => (
	<ActionModalComponent
		open={open}
		handleOk={handleOk}
        name='Discarding KYC changes'
		okText='Yes, discard'
		handleCancel={handleCancel}
		cancelText='No, cancel'
	>
        <Text>Are you sure you want to discard changes in this KYC profile?</Text>
        <br />
        <Text>This action is irreversible</Text>
  </ActionModalComponent>
)

export default DiscardChangesModal