import { FC, useState } from 'react'
import { Row, Col, Select, Space, Drawer } from 'antd'
import Title from 'antd/es/typography/Title'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { moment } from 'modules/kyc-front/ui-elements/FormDate'
import { countries } from 'countries-list'
import styled from 'styled-components'
import FormInput from 'modules/kyc-front/ui-elements/FormInput'
import FormSelect from 'modules/kyc-front/ui-elements/FormSelect'
import FormDate from 'modules/kyc-front/ui-elements/FormDate'
import FormUpload from 'modules/kyc-front/ui-elements/FormUpload'
import { FormCheckboxRadio } from 'modules/kyc-front/ui-elements/FormCheckboxRadio'
import { KycPassportInfo } from 'modules/kyc-front/KycDocsInfo'

const AlignedCol = styled(Col)`
    align-self: flex-end;
`

const TooltipHolder = styled.span`
    position: relative;
    bottom: 2px;
    font-size: 14px;
    margin-bottom: 2px;
    .anticon {
        margin-left: 5px;
    }
`

const FormCheckboxRadioCustom = styled.div`
    display: flex;
    .ant-form-item-label {
        order: 2;
        margin-left: 15px;
        margin-top: 10px;
    }
    .checkContainer {
        order: 1;
        min-width: 200px;
    }
`


interface KProps {
    id?: string,
    person: any,
    type: 'ap' | 'ubo' | 'company' | 'individual',
    personType: 'ubos' | 'authorized-persons' | 'individual' | 'company',
    values: any,
    errors: any,
    handleInputChange: any
    onSelectChange: any,
    onDateChange: any,
    disabled?: boolean,
    printRender?: boolean
}

const KycIdentificationForm: FC<KProps> = ({id, person, type, personType, values, errors, handleInputChange, onSelectChange, onDateChange, disabled, printRender}) => {
        const dateFormat = 'YYYY-MM-DD'
        const { Option } = Select
        const [passportVisible, setPassportVisible] = useState(false)
        const showPassportDrawer = () => {
            setPassportVisible(true)
        };
        const onPassportClose = () => {
            setPassportVisible(false)
        };
        const personIsArchived = person?.archivedAt ? true : false
        return (
            <Space direction='vertical' size={'large'}>
                <div>
                    <Title style={(type === 'ap' || type === 'ubo') && printRender ? {marginBottom: '-10px', marginTop: '-2px'} : {}} level={4}>Passport / ID Card<TooltipHolder className='hide-print'><QuestionCircleOutlined onClick={showPassportDrawer} /></TooltipHolder></Title>
                    <span className={type === 'ap' || type === 'ubo' ? 'hide-print' : ''}>(European national identity card only)</span>
                </div>
                <Drawer rootClassName='hide-print' title="Passport / ID Card" placement="right" onClose={onPassportClose} open={passportVisible}>
                    <KycPassportInfo />
                </Drawer>
                <FormCheckboxRadioCustom>
                    <FormCheckboxRadio id='identityDocument.kind' error={errors.identityDocument?.kind} type='radio' onChange={handleInputChange}
                        options={[{
                                label: 'Passport',
                                value: 'PassportKind'
                            },
                            {
                                label: 'ID Card',
                                value: 'NationalIDCardKind'
                            }
                        ]
                    } disabled={disabled} schemaParent='identityDocument' schemaName='kind' approveType={personType} personId={id} showAdditionalApprove={true} />
                </FormCheckboxRadioCustom>
                <Row gutter={30}>
                   <Col span={12}>
                       <FormInput label='Document Number *' name={`identityDocument.number`} error={errors.identityDocument?.number} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.identityDocument?.number} schemaParent='identityDocument' schemaName='number' personId={id} type={personType} showApprove={!personIsArchived} />
                   </Col>
                   <Col span={12}>
                       <FormInput label='Place of issue *' name={`identityDocument.placeOfIssue`} error={errors.identityDocument?.placeOfIssue} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.identityDocument?.placeOfIssue} schemaParent='identityDocument' schemaName='placeOfIssue' personId={id} type={personType} showApprove={!personIsArchived} />
                   </Col>
                </Row>
                <Row gutter={30} id='documentDates'>
                    <Col span={12}>
                       <FormDate label='Date of issue *' id={`identityDocument.dateOfIssue`} error={errors.identityDocument?.dateOfIssue} defaultValue={values.identityDocument?.dateOfIssue && moment(values.identityDocument.dateOfIssue, dateFormat)}  onChange={(val) => onDateChange(val, `identityDocument.dateOfIssue`)} disabled={disabled} schemaParent='identityDocument' schemaName='dateOfIssue' personId={id} type={personType} hideApprove={personIsArchived} />
                    </Col>
                    <Col span={12}>
                       <FormDate label='Date of expiry *' id={`identityDocument.dateOfExpiry`} error={errors.identityDocument?.dateOfExpiry} defaultValue={values.identityDocument?.dateOfExpiry && moment(values.identityDocument.dateOfExpiry, dateFormat)} onChange={(val) => onDateChange(val, `identityDocument.dateOfExpiry`)} disabled={disabled} schemaParent='identityDocument' schemaName='dateOfExpiry' personId={id} type={personType} hideApprove={personIsArchived} />
                    </Col>
                </Row>
                <Row gutter={30} id='nationalityDocument'>
                    <Col span={24}>
                        <FormSelect defaultValue={values.identityDocument?.nationality} label='Nationality on document *' id={`identityDocument.nationality`} isCountry={true} error={errors.identityDocument ? errors.identityDocument?.nationality : undefined}  placeholder='Nationality on document' onChange={(val) => onSelectChange(val, `identityDocument.nationality`)} disabled={disabled} schemaParent='identityDocument' schemaName='nationality' personId={id} type={personType} hideApprove={personIsArchived} >
                            {values.nationality && values.nationality !== '' && 
                                <>
                                    {values.nationality.split(',').map((val: string, i: number) => {
                                        //@ts-ignore
                                        const country = countries[val]
                                        return <Option key={`identityDocument.nationality` + i} value={val}>{country.name}</Option>
                                    })}
                                </>
                            }
                        </FormSelect>
                    </Col>
                </Row>
                {values.identityDocument?.kind === 'NationalIDCardKind' ? (
                    <Row gutter={30}>
                        <AlignedCol span={12}>
                            <FormUpload /*maxCount={1} */ floatingError={true} name='First page of the ID card' disabled={disabled}  uploadAdmin={true} docType='IDCardFront' isErrorMessage={errors.docs?.IDCardFront} personId={id} personType={personType} type={type} hideApprove={personIsArchived}/>
                        </AlignedCol>
                        <AlignedCol span={12}>
                            <FormUpload /*maxCount={1}*/ floatingError={true} name='Second page of the ID card' disabled={disabled} uploadAdmin={true} docType='IDCardBack' isErrorMessage={errors.docs?.IDCardBack} personId={id} personType={personType} type={type} hideApprove={personIsArchived} />
                        </AlignedCol>
                    </Row>
                ) : (
                    <Row gutter={30}>
                        <Col span={24}>
                            <FormUpload name='Passport' disabled={disabled} uploadAdmin={true} docType='PassportFront' isErrorMessage={errors.docs?.PassportFront} personId={id} personType={personType} type={type} hideApprove={personIsArchived} />
                        </Col>
                    </Row>
                )}
            </Space>
        )

}

export default KycIdentificationForm