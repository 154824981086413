import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc/generated/client2";
import { identityGet } from "../asyncThunks/identityGet";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";

type ProfileResponse = Components.Schemas.ProfileIdentity;

type State = {
  identity: ProfileResponse
};

const emptyProfile = {
    id: '',
    kind: ''
}


const initialState: State = {
    identity: emptyProfile
};

type CaseReducers<State> = {
    identityGetClear: CaseReducerType<State, object>;
};

export const identitySlice = createSlice<State, CaseReducers<State>>({
  name: "identity",
  initialState,
  reducers: {
    identityGetClear: (state, { payload }) => {
        state.identity = initialState.identity
    }
  },
  extraReducers: (builder) => {
    builder.addCase(identityGet.fulfilled, (state, action) => {
        state.identity = action.payload
    });
  },
});

export const { identityGetClear}  = identitySlice.actions;
export default identitySlice.reducer;
