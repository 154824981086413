import { themeColor, themeSize } from 'styles/themeStyles'
import { FC, useEffect, useState } from 'react'
import { countries } from 'countries-list'
import translateCountries from 'utilities/translateCountries'
import { Popover } from 'antd'
import SyncSwitch from 'assets/SyncSwitch.svg'
import SyncPlus from 'assets/SyncPlus.svg'
import SyncMinus from 'assets/SyncMinus.svg'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'hooks/useSelector'
import { kycApplicationChangesGet } from 'store/kyc-front/asyncThunks/kycApplicationChangesGet'
import { useActionStatus } from 'hooks/useActionStatus'

interface EProps {
    type: 'ap' | 'ubo' | 'company' | 'individual' | 'document' | 'authorized-persons' | 'ubos',
    isDocument?: boolean,
    documentName?: string,
    name: string,
    isCountry?: boolean,
    options?: {label: string, value: string | boolean}[]
}


const Edited = styled.span`
    .edited-label {
        font-size: 13px;
        margin-right: 5px;
        color: ${themeColor.orangeWarning};
        label {
            margin-right: 5px;
            color: ${themeColor.gray};
        }
    }
    img {
        width: ${themeSize.heading4}
    }
    &.document-edited {
        margin-left: 5px;
        position: relative;
        bottom: 3px;
        margin-bottom: -3px;
    }
`

type Change = {
    id: string,
    ref: string, 
    slug: string, 
    changeType: "EDITED" | "ADDED" | "DELETED", 
    previousValue?: string
}

const EditedInputInfo: FC<EProps> = ({ type, isDocument, documentName, name, isCountry, options }) => {
    const envs = useSelector((state) => state.general.envs)
    const { kycApplication } = useSelector((state) => state.user2.kycApplication)
    const { applicationChanges } = useSelector((state) => state.user2.kycApplicationChanges)
    const [isEdited, setIsEdited] = useState(false)
    const [editedValues, setEditedValues] = useState<any>({})
    const [existingValues, setExistingValues] = useState<any>({})
    const [editsList, setEditsList] = useState<Change[]>([])
    const [content, setContent] =  useState('')
    const [title, setTitle] =  useState('')
    const [icon, setIcon] = useState(SyncSwitch)
    let el = useLocation();
    let apUboID = el.pathname.split('/')[envs.admin ? 5 : 4];
    let isApOrUbo = type === 'authorized-persons' || type === 'ubos'
    const approvesGettingStatus = useActionStatus(kycApplicationChangesGet)
    useEffect(() => {
        if(approvesGettingStatus.isFullfilled || approvesGettingStatus.isError) {
            if(isApOrUbo) {
                if(type === 'authorized-persons') {
                    const personEl = applicationChanges.authorizedPersons?.find((el: any) => el.id === apUboID)
                    if(personEl) {
                        if(isDocument) {
                            setEditsList(personEl.documents)
                        } else {
                            setEditsList(personEl.changes)
                        }
                    }
                }
                if(type === 'ubos') {
                    const personEl = applicationChanges.beneficialOwners?.find((el: any) => el.id === apUboID)
                    const personValues = kycApplication.values.beneficialOwners?.find((el: any) => el.id === apUboID)
                    if(personEl) {
                        setExistingValues(personValues)
                        if(isDocument) {
                            setEditsList(personEl.documents)
                        } else {
                            setEditsList(personEl.changes)
                        }
                    }
                }
            } else {
                setExistingValues(kycApplication.values)
                if(isDocument) {
                    setEditsList(applicationChanges.documents);
                } else {
                    if(name === 'isSignatory') {
                        setEditsList(applicationChanges.authorizedPersons);
                    } else {
                        setEditsList(applicationChanges.changes);
                    }
                }
            }
            
        }  
    }, [approvesGettingStatus])
    useEffect(() => {
        let modifiedSource: any
        let currentValue : any = ''
        let submittedUser = kycApplication.status === 'SUBMITTED' ? envs.admin ? false : true : false
        let isEdited = true
        if(!submittedUser && editsList?.length) {
            if((name == 'sourceOfWealthBusinessActivities' || name == 'sourceOfWealthProfessionalOccupation' || name == 'sourceOfWealthInheritance' || name == 'sourceOfWealthOther')) {
                modifiedSource = ['sourceOfWealthBusinessActivities', 'sourceOfWealthProfessionalOccupation', 'sourceOfWealthInheritance', 'sourceOfWealthOther']
                let newSource = [...modifiedSource]
                if(newSource.filter(editEl => editsList?.find((edit: any) => edit.slug === editEl)).length) {
                    modifiedSource.forEach((addedEl: string) => {
                        let addedValue: any = editsList.find((edit: Change) => (edit.slug === addedEl && edit.changeType === 'ADDED'))
                        let uncheckedValue: any = editsList.find((edit: Change) => (edit.slug === addedEl && edit.changeType === 'EDITED'))
                        if(uncheckedValue) {
                            if(uncheckedValue.previousValue === 'true') {
                                currentValue += parseSoW(addedEl)
                            }
                        }
                        if(!addedValue && !uncheckedValue) {
                            if(existingValues[addedEl] === 'true') {
                                currentValue += parseSoW(addedEl)
                            }
                        }
                    })
                } else {
                    isEdited = false
                }
            }
            let thisApprove: Change | undefined = modifiedSource && modifiedSource.length ? { ref: '', slug: '', id: '', changeType: 'ADDED', previousValue: `${currentValue}` } : isDocument ? editsList?.find((edit: Change) => edit.slug === name && edit.ref === 'docs' && edit.previousValue === documentName) : editsList?.find((edit: Change) => edit.slug === name && edit.ref === '' || edit.ref + '.' + edit.slug === name )
            if(thisApprove) {
                setEditedValues(thisApprove)
                setIsEdited(isEdited)
            }
        }
    }, [editsList])
    useEffect(() => {
        if(editedValues) {
            parseType()
        }
    }, [editedValues])
    const parseSoW = (SoW: string) => {
        switch(SoW) {
            case 'sourceOfWealthBusinessActivities':
                return 'Business activities'
            case 'sourceOfWealthInheritance':
                return 'Inheritance'
            case 'sourceOfWealthProfessionalOccupation':
                return 'Professional occupation'
            case 'sourceOfWealthOther':
                return 'Other'
            default:
                return 'Other'
        }
    }
    const parseType = () => {
        if(isDocument) {
            setContent("")
            if(editedValues.changeType === 'ADDED') {
                setIcon(SyncPlus)
                setTitle("Document added, not valid untill changes are submitted")
            }
            if(editedValues.changeType === 'DELETED') {
                setIcon(SyncMinus)
                setTitle("Document deleted, valid untill changes are submitted")
            }
        } else {
            setIcon(SyncSwitch)
            setTitle("Value submitted previously, valid untill changes are submited:")
            if(editedValues) {
                if(editedValues.previousValue === '') {
                    setContent('No value')
                } else if(isCountry) {
                    setContent(translateCountries(editedValues.previousValue))
                } else if(options) {
                    let optionLabel = options.find(op => op.value === editedValues.previousValue)?.label
                    if(optionLabel) {
                        setContent(optionLabel)
                    }
                } else {
                    setContent(editedValues.previousValue)
                }
            } else {
                setContent('No value')
            }
        }
    }
    
    return (
        <>
            {isEdited &&
                <Popover overlayClassName='popover-edit' color={themeColor.orangeLight} overlayStyle={{maxWidth: "1000px"}} zIndex={99} title={title} content={content}>
                    <Edited className={document ? envs.admin ? 'document-edited admin' : 'document-edited' : ''}>
                        <img src={icon} alt={'Edited'} />
                    </Edited>
                </Popover>
            }
        </>
    )
}

export default EditedInputInfo