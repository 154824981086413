import {
	IndividualBody,
} from 'store/kyc-front/asyncThunks/kycApplicationGet'

export type SourceOfWealthProps = Pick<IndividualBody, 'sourceOfWealthBusinessActivities'|'sourceOfWealthInheritance'|'sourceOfWealthProfessionalOccupation'|'sourceOfWealthOther'>
export const translateSourceOfWealthType = (values: SourceOfWealthProps): string[] => {
	const result = []
	if(values.sourceOfWealthBusinessActivities === 'true'){
		result.push('Business activities')
	}
	if(values.sourceOfWealthInheritance === 'true'){
		result.push('Inheritance')
	}
	if(values.sourceOfWealthProfessionalOccupation === 'true'){
		result.push('Professional occupation')
	}
	if(values.sourceOfWealthOther === 'true'){
		result.push('Other')
	}
	return result
}