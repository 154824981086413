import { View } from '@react-pdf/renderer'
import { FC, ReactNode } from 'react'
import {
	AuthorizedPerson, BeneficialOwner,
	CompanyBody,
	IndividualBody,
} from '../../../store/kyc-front/asyncThunks/kycApplicationGet'
import { descriptions } from '../forms/common'
import { FieldsSection, ListSection, SignatureSection, TitleSection } from '../../../pdf/PdfDocument'
import translateCountries from 'utilities/translateCountries'
import translateSoFName from 'utilities/translateSoFName'
import { SourceOfWealthProps, translateSourceOfWealthType } from 'utilities/translateSourceOfWealth'

const getEstimatedNetWorthRange = (amount?: string): string => {
	if(amount === '5000001'){
		return '> 5 milion'
	}
	if(amount === '1000001'){
		return '1 - 5 milion'
	}
	if(amount === '100000'){
		return '100,000 - 1 milion'
	}
	if(amount === '0'){
		return '< 100,000'
	}
	return ''
}
const getUboType = (value: BeneficialOwner['roleType']): string => {
	switch (value){
		case 'HoldsMoreThan25PercentageOfShares': return descriptions.roleTypeHoldsMoreThan25PercentageOfShares
		case 'UltimatelyControlOverBusiness': return descriptions.roleTypeUltimatelyControlOverBusiness
		case 'MemberOfGoverningBody': return descriptions.roleTypeMemberOfGoverningBody
		default: return ''
	}
}
const getAuthorizedRoleType = (value: AuthorizedPerson['roleType']): string => {
	switch (value){
		case 'Director': return 'Director'
		case 'OtherIndividual': return 'Other'
		default: return ''
	}
}
const getNationalityLabel = (value?: string): string => value?.includes(',') ? 'Nationalities':'Nationality'

const PersonDetailsSections:FC<{person: Partial<Omit<AuthorizedPerson, 'roleType'>>, role?: string, level?: number, preChildren?: ReactNode}> = ({person, role, level, preChildren}) => <>
	<View wrap={false}>
		{preChildren}
		<TitleSection level={level}>Personal details</TitleSection>
		<FieldsSection fields={[
			{ label: 'Name', value: person.firstName },
			{ label: 'Last name', value: person.lastName },
			{ label: 'Role', value: role, hide: !role },
			{ label: 'Date of birth', value: person.dateOfBirth },
			{ label: 'Place of birth', value: person.placeOfBirth },
			{ label: getNationalityLabel(person.nationality), value: translateCountries(person.nationality) }
		]} columns={2}/>
	</View>
	<FieldsSection fields={[{ label: 'Current occupation', value: person.occupation }]}/>
</>
const ContactDetailsSections:FC<{person: Partial<Omit<AuthorizedPerson, 'roleType'>>, level?: number}> = ({person, level}) => <>
	<View wrap={false}>
		<TitleSection level={level}>Contact details</TitleSection>
		<FieldsSection fields={[
			{label: 'Residential address', value: person.residentialAddress?.address},
			{label: 'Postcode', value: person.residentialAddress?.postcode},
			{label: 'City', value: person.residentialAddress?.city},
			{label: 'Residence country', value: translateCountries(person.residentialAddress?.country)},
			{label: 'Email address', value: person?.email},
			{label: 'Mobile number', value: person?.phone, hide: !person?.phone},
		]} columns={2}/>
	</View>
</>
const IdentificationSections:FC<{person: Partial<Omit<AuthorizedPerson, 'roleType'>>, level?: number}> = ({person, level}) => <>
	<View wrap={false}>
		<TitleSection level={level}>Identification - {person.identityDocument?.kind === 'NationalIDCardKind' ? 'ID Card' : 'Passport'}</TitleSection>
		<FieldsSection fields={[
			{label: 'Document number', value: person.identityDocument?.number},
			{label: 'Place of issue', value: person.identityDocument?.placeOfIssue},
			{label: 'Date of issue', value: person.identityDocument?.dateOfIssue},
			{label: 'Date of expiry', value: person.identityDocument?.dateOfExpiry},
			{label: 'Nationality on document', value: translateCountries(person.identityDocument?.nationality)}
		]} columns={2}/>
	</View>
</>
const DeclarationsSection:FC<{person: Partial<Pick<BeneficialOwner, 'isUSNationality'|'isPoliticallyExposedPerson'>>, level?: number}> = ({ person, level}) => <>
	<View wrap={false}>
		<TitleSection level={level}>Declarations</TitleSection>
		<FieldsSection fields={[
					{label: 'Politically Exposed Person', value: (person.isPoliticallyExposedPerson === 'true' ? descriptions.politicallyExposedPerson : descriptions.nonPoliticallyExposedPerson) + descriptions.politicallyExposedPersonExplanation},
		]}/>
	</View>
	<FieldsSection fields={[
		{label: 'US Person', value: (person.isUSNationality === 'true' ? descriptions.uSNationality : descriptions.nonUSNationality) + descriptions.uSNationalityExplanation},
		{label: 'Correctness and changes', value: descriptions.correctness1},
		{value: `${descriptions.correctness2} ${descriptions.correctness3}`},
	]}/>
</>
const SourceOfWealthSection:FC<{values: SourceOfWealthProps & Pick<IndividualBody, 'sourceOfWealthDescription'>, isUbo?: boolean}> = ({isUbo, values}) => <View wrap={false}>
	<FieldsSection fields={[{label: 'Source of wealth', value: `${isUbo? descriptions.sourceOfWealthUbo : descriptions.sourceOfWealth}:`}]}/>
	<ListSection points={translateSourceOfWealthType(values)}/>
	<FieldsSection fields={[{label: 'Source of wealth description', value: values.sourceOfWealthDescription}]}/>
</View>

export const CompanyPdfContent:FC<{values: CompanyBody}> = ({ values }) => {
	const isHeadOfficeSameAsRegistered = values.headOfficeAddress.sameAsRegistered === 'true'
	
	return <>
		<View wrap={false}>
			<TitleSection>Company details</TitleSection>
			<FieldsSection fields={[
				{ label: 'Name of legal entity', value: values.name },
				{ label: 'Legal form', value: values.legalForm },
			]}/>
			<FieldsSection fields={[
				{ label: 'Date of incorporation', value: values.dateOfIncorporation },
				{ label: 'Registration number', value: values.registrationNumber },
				{ label: 'Place of incorporation', value: values.placeOfIncorporation },
				{ label: 'Corporate website', value: values.website },
			]} columns={2}/>
		</View>
		<View wrap={false}>
			<TitleSection>Registration address</TitleSection>
			<FieldsSection fields={[
				{ label: 'Address', value: values.registeredAddress.address },
				{ label: 'Postcode', value: values.registeredAddress.postcode },
				{ label: 'City', value: values.registeredAddress.city },
				{ label: 'Country', value: translateCountries(values.registeredAddress.country) },
			]} columns={2}/>
		</View>
		<View wrap={false}>
			<TitleSection>Address of head office</TitleSection>
			<FieldsSection fields={ isHeadOfficeSameAsRegistered ? [
					{ value: 'same as registered address' }
				] : [
					{ label: 'Address', value: values.headOfficeAddress.address },
					{ label: 'Postcode', value: values.headOfficeAddress.postcode },
					{ label: 'City', value: values.headOfficeAddress.city },
					{ label: 'Country', value: translateCountries(values.headOfficeAddress.country) },
			]} columns={2}/>
		</View>
		<View wrap={false}>
			<TitleSection>Operations and activities</TitleSection>
			<FieldsSection fields={[
				{ label: 'Main countries and regions of geographical area of operation', value: translateCountries(values.geographicalArea) },
				{ label: 'Number of direct employees', value: values.numberOfEmployees },
			]}/>
		</View>
		<View wrap={true}>
			<FieldsSection fields={[
				{ label: 'Nature of business activities and business model', value: values.activitiesAndBusinessModel },
				{ label: 'Does the company have any business license or is supervised by a financial markets regulator?', value: values.businessLicence },
				{ label: 'Approximate annual turnover (EUR)', value: values.annualTurnoverEUR },
			]}/>
		</View>
		<View wrap={false}>
			<TitleSection>Financials</TitleSection>
			<FieldsSection fields={[
				{ label: `${descriptions.estimatedExchangeAmountFuture} (EUR)`, value: values.estimatedAmountOfEUR },
				{ label: 'Funding of business activities', value: translateSoFName(values.fundingOfBusinessType) },
				{ label: 'Explanation', value: values.fundingOfBusinessDescription },
			]}/>
		</View>
		{values.authorizedPersons.map((person, i) =>
			<View key={i}>
				{/* title has to be joined with first View to avoid wrapping title itself */}
				<PersonDetailsSections person={person} role={getAuthorizedRoleType(person.roleType)} level={2}
				 preChildren={<>
				  {i===0 ? <TitleSection>Authorized persons</TitleSection> : null}
				  <TitleSection level={2}>{++i}. {getAuthorizedRoleType(person.roleType)}: {person.firstName} {person.lastName}</TitleSection>
				 </>}
				/>
				<ContactDetailsSections person={person} level={2}/>
				<IdentificationSections person={person} level={2}/>
			</View>
		)}
		{values.beneficialOwners.map((person, i) =>
			<View key={i}>
				{/* title has to be joined with first View to avoid wrapping title itself */}
				<PersonDetailsSections person={person} level={2}
				 preChildren={<>
				  {i===0 ? <TitleSection>Ultimate Beneficial Owner</TitleSection> : null}
				  <TitleSection level={2}>{++i}. UBO: {person.firstName} {person.lastName}</TitleSection>
				 </>}
				/>
				<ContactDetailsSections person={person} level={2}/>
				<IdentificationSections person={person} level={2}/>
				<View wrap={false}>
					<TitleSection level={2}>Source of wealth</TitleSection>
					<FieldsSection fields={[
						{label: 'Beneficial Owners', value: descriptions.beneficialOwnersUbo1},
						{value: getUboType(person.roleType)}
					]}/>
				</View>
				<SourceOfWealthSection values={person} isUbo />
				<View wrap={false}>
					<FieldsSection fields={[
						{label: 'Total estimated net worth (EUR)', value: getEstimatedNetWorthRange(person.estimatedNetWorth)},
						{label: 'Tax Compliance', value: descriptions.taxCompliance},
					]}/>
				</View>
				<DeclarationsSection person={person} level={2}/>
			</View>
		)}
		<View wrap={false}>
			<TitleSection>Signatories declarations</TitleSection>
			<FieldsSection fields={[
				{ value: `1. ${descriptions.signatoriesDeclarations1}` },
				{ value: `2. ${descriptions.signatoriesDeclarations2}` },
				{ value: `3. ${descriptions.signatoriesDeclarations3_1} ${descriptions.signatoriesDeclarations3_2} ${descriptions.signatoriesDeclarations3_3}`},
				{ value: `${descriptions.signatoriesDeclarations4}` }
			]}/>
			<SignatureSection signatures={
				values.authorizedPersons?.filter((person) => person.isSignatory === 'true').map(person => (
					{ labels: [`Customer's signature`, `${person.firstName} ${person.lastName}`] }
				))
			}/>
		</View>
	</>
}

export const IndividualPdfContent:FC<{values: IndividualBody}> = ({ values }) => {
	return <>
		<PersonDetailsSections person={values}/>
		<ContactDetailsSections person={values}/>
		<IdentificationSections person={values} />
		<View wrap={false}>
			<TitleSection>Source of wealth</TitleSection>
			<FieldsSection fields={[{label: 'Beneficial Owners', value: descriptions.beneficialOwners}]}/>
		</View>
		<SourceOfWealthSection values={values} />
		<View wrap={false}>
			<FieldsSection fields={[
					{label: 'Total estimated net worth (EUR)', value: getEstimatedNetWorthRange(values.estimatedNetWorth)},
					{label: `${descriptions.estimatedExchangeAmountFuture} (EUR)`, value: values.estimatedAmountOfEUR},
					{label: 'Tax Compliance', value: descriptions.taxCompliance},
			]}/>
		</View>
		<View wrap={false}>
			<DeclarationsSection person={values}/>
			<SignatureSection signatures={[{ labels: [`Customer's signature`, `${values.firstName} ${values.lastName}`] }]}/>
		</View>
	</>
}

