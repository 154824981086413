import { Popover, Timeline } from 'antd'
import SyncSwitch from 'assets/SyncSwitch.svg'
import SyncPlus from 'assets/SyncPlus.svg'
import _ from 'lodash'
import { CheckCircleFilled } from '@ant-design/icons'
import { FC, useEffect } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { themeColor, themeSize } from 'styles/themeStyles'
import { useActionStatus } from 'hooks/useActionStatus'
import { dispatch } from 'store/store'
import { kycApplicationValidate } from 'store/kyc-front/asyncThunks/kycApplicationValidate'

import { useSelector } from 'hooks/useSelector'

import { setEditedFormConfirmed } from 'store/kyc-front/slices/navSlice'
import { kycApplicationChangesGet } from 'store/kyc-front/asyncThunks/kycApplicationChangesGet'


const CustomLinkSpan = styled.span`
    cursor: pointer;
    &.disabled-link {
      pointer-events: none;
      li {
        color: ${themeColor.grayLight};
      }
    }
    &.has-substeps {
        margin-left: 15px;
        font-style: italic;
    }
    &.substep {
        margin-left: 30px;
        font-style: italic;
    }
`
const KycStep = styled.span`
    font-size: 13px!important;
    .ant-timeline-item-content {
        top: -4px;
        margin-left: 20px;
    } 
    color: ${themeColor.gray};
    &.active {
        text-decoration: underline;
        color: #000;
        font-weight: bold;
    }
    
`

const IconBadge = styled.span`
    .anticon {
        font-size: ${themeSize.heading4};
        left: 5px;
        position: relative;
        top: 1px;
    }
    &.success {
        color: ${themeColor.green};
    }
    &.error {
        color: ${themeColor.red};
    }
    &.edit {
        width: ${themeSize.heading4};
        .anticon {
            top: 1px;
            img {
                width: ${themeSize.heading4};
            }
        }
    }
`


interface IProps {
    adminView?: boolean,
}



export const KycSteps: FC<IProps> = () => {
    const nav = useSelector((state) => state.general.nav);
    const envs = useSelector((state) => state.general.envs);
    const { validation, stepsScheme, kycApplication } = useSelector((state => state.user2.kycApplication))
    const { applicationChangesByStep } = useSelector((state) => state.user2.kycApplicationChanges)
    const kycApplicationValidatedStatus = useActionStatus(kycApplicationValidate);
    const kycApplicationChangesStatus = useActionStatus(kycApplicationChangesGet)
    let el = useLocation();
    let apUboID = el.pathname.split('/')[envs.admin ? 5 : 4]
    useEffect(() => {
        if(!kycApplicationValidatedStatus.isFullfilled && envs.profileId !== '') {
            dispatch(kycApplicationValidate({ params: { id: envs.profileId} }));
        } 
        if(!kycApplicationChangesStatus.isFullfilled && envs.profileId !== '') {
            dispatch(kycApplicationChangesGet({params: {id: envs.profileId}}))
        }
    }, [envs])
    const confirm = (link: string) => {
        dispatch(setEditedFormConfirmed({onConfirm: true, onConfirmLink: link, showErrors: false, refId: apUboID}));
    }
    let {step} = nav.nav
    return (
        <>
            <Timeline
                //@ts-ignore
                items={stepsScheme.map((stepItem, i) => {
                    let isActiveSubstep = stepItem.substep ? apUboID === stepItem.refId : false;
                    let isActiveSubstep2 = stepItem.substep ? apUboID === stepItem.refId ? stepItem.substep === nav.nav.substep ? true : false : false : false
                    let personHasErrors = false
                    let personHasChanges = false
                    if(stepItem.hasSubsteps) {
                        personHasChanges = applicationChangesByStep.find(change => change.refId === stepItem.refId && (change.changesInStep.length || change.docChangesInStep.length)) ? true : false
                        personHasErrors = validation.find(err => err.refId === stepItem.refId && err.validationErrors.length) ? true : false
                    }
                    if(stepItem.substep && !isActiveSubstep) {
                        return undefined
                    }
                    const isActiveStep = stepItem.hasSubsteps ? false : stepItem.substep ? isActiveSubstep && isActiveSubstep2 ? true : false : stepItem.step === step ? true : false
                    const isDocStep = stepItem.fields && stepItem.fields[0] === 'docs.OtherDocumentKind'
                    const validationResult = validation[i]
                    const validationResultPrev = validation[i-1]
                    const stepPrev = stepsScheme[i-1]
                    const statusValue = kycApplication.status
                    let previousItem = null;
                    for (let i = 0; i < validation.length; i++) {
                        if (validation[i].link === stepItem.link) {
                            break;
                        }
                        previousItem = validation[i];
                    }
                    let submittedUser = kycApplication.status === 'SUBMITTED' ? envs.admin ? false : true : false
                    const showAddedIcon = !submittedUser && applicationChangesByStep[i]?.changeType === 'ADDED'
                    const showEditedIcon = !submittedUser ? (applicationChangesByStep[i]?.changesInStep.length || applicationChangesByStep[i]?.docChangesInStep.length) ? true : personHasChanges ? true : false : false
                    const showCheckIcon = showEditedIcon || showAddedIcon ? false : statusValue === 'ACCEPTED' || statusValue === 'SUBMITTED' ? true : isDocStep && (validationResultPrev?.validationErrors?.length || stepPrev.type ==='beneficialOwners') ? false : validationResult?.validationErrors?.length < 1 && i + 1 !== stepsScheme.length && !stepItem.type ? personHasErrors ? false : true : false
                    return {
                        color: isActiveStep ? themeColor.primaryColor : themeColor.gray,
                        children: (
                            <span key={'step'+ (i + 1)}>
                                <CustomLinkSpan className={stepItem.substep ? 'substep' : stepItem.hasSubsteps ? 'has-substeps' : ''} onClick={() => confirm(stepItem.link) } key={'step'+ (i + 1)} >
                                    <KycStep className={isActiveStep ? 'active' : ''}>{stepItem.name}
                                            <>
                                            <Popover overlayClassName='popover-edit' color={themeColor.orangeLight} overlayStyle={{maxWidth: "1000px"}} zIndex={99} title={showEditedIcon ? `Step modified, changes are not valid untill submitted` : `Step ${showAddedIcon ? 'added' : 'deleted'}, ${showAddedIcon ? 'not valid' : 'valid'} untill changes are submited`}>
                                                {showAddedIcon && <IconBadge style={{'position': 'absolute'}} className='edit'><img className='anticon' src={SyncPlus} alt={'Added'} /></IconBadge>}
                                            </Popover>
                                            <Popover overlayClassName='popover-edit' color={themeColor.orangeLight} overlayStyle={{maxWidth: "1000px"}} zIndex={99} title={showEditedIcon ? `Step modified, changes are not valid untill submitted` : `Step ${showAddedIcon ? 'added' : 'deleted'}, ${showAddedIcon ? 'not valid' : 'valid'} untill changes are submited`}>
                                                {showEditedIcon && <IconBadge style={{'position': 'absolute'}} className='edit'><img className='anticon' src={SyncSwitch} alt={'Edited'} /></IconBadge>}
                                            </Popover>
                                            </>
                                        {showCheckIcon && <IconBadge style={{'position': 'absolute'}} className='success'><CheckCircleFilled /></IconBadge>}
                                    </KycStep>
                                    
                                </CustomLinkSpan>
                            </span>
                        )
                    }
                })}
              />
        </>
    )
}