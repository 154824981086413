import { FC, useEffect, useState } from 'react'
import { Row, Col, Space, message, Popconfirm, Divider, Collapse, Empty, Spin, Dropdown, Button, theme, Menu } from 'antd'
import { DownOutlined, PlusOutlined, QuestionCircleOutlined, RightOutlined } from '@ant-design/icons'
import { moment } from 'modules/kyc-front/ui-elements/FormDate'
import type { MenuProps } from 'antd'
import Text from 'antd/es/typography/Text'
import { Link, useNavigate } from 'react-router-dom'
import _, { initial } from 'lodash'
import styled from 'styled-components'
import { useSelector } from 'hooks/useSelector'
import { dispatch } from 'store/store'
import { useActionStatus } from 'hooks/useActionStatus'
import { kycApplicationSectionCreate } from 'store/kyc-front/asyncThunks/kycApplicationSectionCreate'
import { kycApplicationSectionCreateClear, kycApplicationSectionDeleteClear, kycApplicationSectionUpdateClear } from 'store/kyc-front/slices/kycApplicationSlice'
import { ButtonUpper } from 'modules/kyc-front/ui-elements/Buttons'
import { CustomCollapse, CollapsePanel } from 'modules/kyc-front/ui-elements/CustomCollapse'
import { kycApplicationGet } from 'store/kyc-front/asyncThunks/kycApplicationGet'
import { themeColor, themeSize, themeWeight } from 'styles/themeStyles'
import { kycApplicationSectionUpdate } from 'store/kyc-front/asyncThunks/kycApplicationSectionUpdate'
import { kycApplicationValidate } from 'store/kyc-front/asyncThunks/kycApplicationValidate'
import { setFormUpdated } from 'store/kyc-front/slices/navSlice'
import { kycApplicationSectionDelete } from 'store/kyc-front/asyncThunks/kycApplicationSectionDelete'
import { NavItem } from '../kyc-layout/KycLayout'
import { kycApplicationChangesGet } from 'store/kyc-front/asyncThunks/kycApplicationChangesGet'
const { Panel } = Collapse

const CustomDivider = styled(Divider)`
	margin-bottom: 0;
	&.new {
		margin-left: -20px;
    	margin-right: -20px;
    	width: initial;
	}
`

const PopconfirmCustom = styled(Popconfirm)`
    .ant-popover {
        min-width: 350px;
        text-transform: none;
    }
`

const Profile = styled.div`
	background-color: ${themeColor.blueLight};
	padding: 15px 20px;
	margin-bottom: 25px;
	text-align: center;
	font-weight: ${themeWeight.bold};
	cursor: pointer;
`

const ButtonCustom = styled(Button)`
	text-transform: uppercase;
	font-size: 16px;
	padding: 0 10px;
	height: auto;
`

interface KProps {
    disabled: boolean;
	listing: 'authorized-persons' | 'ubos';
}


const KycPersonsListingForm: FC<KProps> = ({disabled, listing}) => {
    const envs = useSelector((state) => state.general.envs)
    const nav = useSelector((state) => state.general.nav)
	const { applicationChangesByStep, applicationChanges } = useSelector((state) => state.user2.kycApplicationChanges)
    const { kycApplication, newSection, updatedKycApplicationSection, sectionDeleted } = useSelector((state) => state.user2.kycApplication)
	const [newPanelAdded, setNewPanelAdded] = useState(false)
	const [messageShown, setMessageShown] = useState(true)
	let [isDeleting, setDeleting] = useState(false)
	let [popupAddApVisible, setPopupAddApVisible] = useState('')
	const [created, setCreated] = useState(false)
	const [createdApReferenceValues, setCreatedApReferenceValues] = useState({})
	const [showArchived, setShowArchived] = useState(false)
	const { isFullfilled } = useActionStatus(kycApplicationGet)
	const createStatus = useActionStatus(kycApplicationSectionCreate)
	const updateStatus = useActionStatus(kycApplicationSectionUpdate)
	const deleteEl = useActionStatus(kycApplicationSectionDelete);
	const errorsState = useSelector((state) => state.general.errorsState)
	const isAP = listing === 'authorized-persons'
	const isUbo = listing === 'ubos'
	const changesParent = isAP ? applicationChanges.authorizedPersons : applicationChanges.beneficialOwners
	const deletedList = changesParent?.filter((person: any) => person.changeType === 'DELETED')
    const kycValues = kycApplication.values
	const navigate = useNavigate()
	//@ts-ignore
	const personsValues = isAP ? kycValues.authorizedPersons : kycValues.beneficialOwners
    useEffect(() => {
    	if(messageShown) {
            //@ts-ignore
            if(createStatus.isFullfilled && (newSection.created === true)) {
				if(_.isEmpty(createdApReferenceValues)) {
               		message.success('Person was successfully added');
					dispatch(kycApplicationValidate({params: {id: envs.profileId}}));
					dispatch(kycApplicationChangesGet({params: {id: envs.profileId}}))
					setMessageShown(false);
				} else {
					//@ts-ignore
					dispatch(kycApplicationSectionUpdate({params: {applicationId: envs.profileId, sectionName: 'ubos', sectionId: newSection.id}, data: createdApReferenceValues}))
					setCreatedApReferenceValues({});
				}
               	dispatch(kycApplicationSectionCreateClear({}));
               	dispatch(kycApplicationGet({ params: { id: envs.profileId} }))
               	setCreated(true);
            }
            if(createStatus.isError) {
               message.error(' Some error occurred while adding new person');
               dispatch(kycApplicationSectionCreateClear({}));
               setMessageShown(false);
            }
      }
	}, [newSection, messageShown, createStatus]);
	useEffect(() => {
    	if(messageShown) {
            //@ts-ignore
            if(updateStatus.isFullfilled && (updatedKycApplicationSection === true)) {
               	message.success('Person was successfully added');
				setMessageShown(false);
               	dispatch(kycApplicationSectionUpdateClear({}));
               	dispatch(kycApplicationGet({ params: { id: envs.profileId} }))
				dispatch(kycApplicationValidate({params: {id: envs.profileId}}));
				dispatch(kycApplicationChangesGet({params: {id: envs.profileId}}))
               	setCreated(true);
            }
            if(createStatus.isError) {
               message.error(' Some error occurred while adding new person');
               dispatch(kycApplicationSectionCreateClear({}));
               setMessageShown(false);
            }
      }
	}, [updatedKycApplicationSection, messageShown, updateStatus]);
	useEffect(() => {
        if(messageShown) {
            if(deleteEl?.isFullfilled && isDeleting) {
                message.success('Element was successfully deleted')
                dispatch(kycApplicationSectionDeleteClear({}))
                dispatch(kycApplicationGet({ params: { id: envs.profileId } }))
                dispatch(kycApplicationValidate({ params: { id: envs.profileId, step: nav.nav.step} }))
				dispatch(kycApplicationChangesGet({params: {id: envs.profileId}}))
                setMessageShown(false)
                setDeleting(false)
            }
            if(deleteEl?.isError) {
                message.error('Some error occurred while deleting this element')
                dispatch(kycApplicationSectionDeleteClear({}))
                setMessageShown(false);
            }
        }
        
    }, [sectionDeleted]);
	useEffect(() => {
      if(isFullfilled && (created === true)) {
         setNewPanelAdded(true);
         setCreated(false);
				let timeId;
				clearTimeout(timeId)
				timeId = setTimeout(() => {
			      setNewPanelAdded(false)
			}, 1500)
      }
    }, [isFullfilled, created]);
	const handleMenuClick = (ap: any) => {
		if(ap && ap.id) {
			handleApClick(ap);
		} else {
			addPerson();
		}
	};
	
	const items: MenuProps['items'] = [
		{
		  label: 'Create from Authorized Person',
		  key: '2',
		  children: kycValues?.authorizedPersons?.length
			? kycValues?.authorizedPersons.map((ap: any, i: number) => {
				let hasUbo = _.find(kycValues.beneficialOwners, function (o) {
				  return o.referencedAuthorizedPerson === ap.id;
				});
				if (!hasUbo && ap.archivedAt == undefined) {
				  return {
					label: (ap.firstName ? ap.firstName : 'New') + ' ' + (ap.lastName ? ap.lastName : 'Person'),
					key: '2-' + i,
					onClick: () => handleMenuClick(ap),
				  };
				} else return;
			  })
			: [],
		},
		{
		  label: (
			<>
			  Create new Beneficial Owner <PlusOutlined style={{position: 'relative', left: 12, color: themeColor.gray}} />
			</>
		  ),
		  key: '1',
		  onClick: () => handleMenuClick(undefined),
		},
	];
	const menuProps = {
		items,
		onClick: ( domEvent: any ) => {
		  domEvent.stopPropagation();
		  handleMenuClick(domEvent)
		},
	};
	const menu = (
		<Menu
		  mode="vertical"
		  items={items}
		/>
	  );
	const addPerson = () => {
        dispatch(kycApplicationSectionCreate({params: {id: envs.profileId, sectionType: listing}}));
		setMessageShown(true);
	}
	const confirmAdd = (person: any) => {
		setPopupAddApVisible('');
		handleApClick(person)
	}
  	const handleShowArchived = () => {
  		setShowArchived(!showArchived);
  	}
	const cancelAddAp = () => {
		setPopupAddApVisible('')
	}
	const handleAddApVisibleChange = (id: string) => {
		setPopupAddApVisible(popupAddApVisible === '' ? id : '');
	}
	const cancelDelete = () => {
        return
    }
	const handleApClick = (person: any) => {
    	const identityDocument: any = person.identityDocument;
    	const residentialAddress: any = person.residentialAddress;
    	let initialValues = {
			firstName: person.firstName,
			lastName: person.lastName,
			dateOfBirth: person.dateOfBirth,
			placeOfBirth: person.placeOfBirth,
			residentialAddress: {
				address: residentialAddress?.address,
				postcode: residentialAddress?.postcode,
				city: residentialAddress?.city,
				country: residentialAddress?.country
			},
			nationality: person.nationality,
			occupation: person.occupation,
			email: person.email,
			phone: person.phone,
			identityDocument: {
				kind: identityDocument?.kind,
				number: identityDocument?.number,
				placeOfIssue: identityDocument?.placeOfIssue,
				dateOfIssue: identityDocument?.dateOfIssue,
				dateOfExpiry: identityDocument?.dateOfExpiry,
				nationality: identityDocument?.nationality
	        },
	   	}
		//@ts-ignore
	   	dispatch(kycApplicationSectionCreate({params: {id: envs.profileId, sectionType: listing}, data: {referencedAuthorizedPerson: person.id}}));
		setCreatedApReferenceValues(initialValues)
    	setMessageShown(true);
    }
	let array = personsValues ? [...personsValues] : [];
	let archivedArray:any[] = [];
	if(array) {
		if(envs.admin) {
			let adminArray:string[] = [];
			array.forEach(el => {
				if(el.archivedAt) {
					archivedArray.push(el);
				} else {
                    //@ts-ignore
					adminArray.push(el);
				}
			})
            //@ts-ignore
			array = adminArray;
		}
		array.sort(function(a,b){
			return moment(b.createdAt).diff(moment(a.createdAt), 'milliseconds')
		});
		archivedArray.sort(function(a,b){
			return moment(b.createdAt).diff(moment(a.createdAt), 'milliseconds')
		});
	}  
	const handleFormDelete = (id: string) => {
        dispatch(setFormUpdated({updated: true, form: [''], formName: envs.type}));
        setDeleting(true);
        setMessageShown(true);
        dispatch(kycApplicationSectionDelete({params: {applicationId: envs.profileId, sectionName: listing, sectionId: id}}))
    }
	const handleClick = (key: string) => {
		navigate(envs.admin ? envs.routeSuffix + '/' + NavItem.CLIENT_PROFILES + '/' + envs.profileId + '/step' + nav.nav.step + '/' + key : envs.routeSuffix + '/' + NavItem.CLIENT_PROFILE + '/step' + nav.nav.step + '/' + key)
	}
    return (
        <Space direction='vertical' size={'large'}>
            {isAP ? 
				<Text>Please name at least one person with signatory rights for the company. Please name all persons who will act on behalf of the company towards us.</Text>
			: 
				<>
					<Text><b>Please add all UBOs with 25% or more ownership and/or controlling rights.</b></Text>
					<div style={{'marginBottom': '15px'}}>
						<Text>Add new UBO or copy the data when UBO is one of the Directors / Users.</Text><br />
						<Text style={{color: themeColor.grayLight}}>When you choose someone from the Directors / Users, you have to fill in additional data.</Text>
					</div>
				</>
			}
      		<div style={{'marginBottom': '-17px'}}>
				{isAP ? (
					<ButtonUpper className='btn-sm' btnType="primary" ghost onClick={addPerson} disabled={disabled}>Add authorized person</ButtonUpper>
				) : (
					<Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
						<ButtonCustom className='btn-sm' type="primary" ghost disabled={disabled}>Add beneficial owner  <DownOutlined /></ButtonCustom>
					</Dropdown>
				)}
				<CustomDivider className={newPanelAdded === true ? 'new' : ''} />
                {/*//@ts-ignore*/}
	      		{personsValues?.length && personsValues.find((el: any) => !el.archivedAt) ? (
	      			 <CustomCollapse
	      			 		id='authorizedPersonsForm'
				          	bordered={false}
				          	ghost={true}
				          	expandIcon={(props: any) => (
								<div>
									<div>
										<Link to={envs.admin ? `${envs.routeSuffix}/${NavItem.CLIENT_PROFILES}/${envs.profileId}/step${nav.nav.step}/${props.panelKey}` : `${envs.routeSuffix}/${NavItem.CLIENT_PROFILE}/step${nav.nav.step}/${props.panelKey}`}><ButtonUpper btnType="default">{disabled ? 'Details' : 'Edit'} <RightOutlined/></ButtonUpper></Link>
										<br />
										<PopconfirmCustom
											title={isUbo ? "Are You sure You want to delete this Beneficial Owner?" : "Are You sure You want to delete this Authorized Person?"}
											onConfirm={() => handleFormDelete(props.panelKey)}
											onCancel={cancelDelete}
											okText="Yes, delete"
											cancelText="Cancel"
											getPopupContainer={(trigger) => trigger}
											icon={<QuestionCircleOutlined />}
										>
											<ButtonUpper className='delete' btnType="text" disabled={disabled} ghost>Delete</ButtonUpper>
										</PopconfirmCustom>
									</div>
								</div>
							)}
				          	expandIconPosition='right'
				        >
				        	{array.map((person, i) => {
					        	return (
					        		<Panel className={i === 0 && newPanelAdded === true ? 'new' : ''} key={person.id} header={(<CollapsePanel onClick={() => handleClick(person.id)} archived={person.archivedAt ? true : false} name={(person.firstName ? person.firstName : 'New') + ' ' + (person.lastName ? person.lastName : 'Person')} edited={applicationChangesByStep.find(change => change.refId === person.id && (change.changesInStep.length || change.docChangesInStep.length) ) ? true : false} added={applicationChangesByStep.find(change => change.refId === person.id && change.changeType === 'ADDED') ? true : false} role={isAP ? person.roleType ? (person.roleType ==='OtherIndividual' ? 'Other' : person.roleType as string) : 'Role' : 'Ultimate Beneficial Owner'} />)}>
                                        <></>
							      	</Panel>
					        	)
				        	})}
							{deletedList?.length &&
								<>
									{deletedList.map((person: any, i: number) => {
										return (
											<Panel className='deleted' key={person.id} header={(<CollapsePanel name={person.previousValue} deleted={true} role={isAP ? person.roleType ? (person.roleType ==='OtherIndividual' ? 'Other' : person.roleType as string) : 'Role' : 'Ultimate Beneficial Owner'} />)}>
												<></>
											</Panel>
										)
									})}
								</>
							}

				    </CustomCollapse>
				) : (
					<>
						{isFullfilled ? (
							<Empty style={{'marginTop':'50px', 'marginBottom' : '30px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={isAP ? 'No Authorized Person' : 'No Beneficial Owner'} />
						) : (
							<Spin size="large" style={{'marginTop': '30px'}} />
						)}
					</>
					
				)}
			</div>
			{envs.admin &&
				<>
					<Row>
						<Col span={24} style={{'textAlign': 'right'}}>
							<ButtonUpper btnType='dashed' className='btn-sm dashed-primary right' ghost onClick={handleShowArchived}>{showArchived ? 'Hide archived APs' : 'Show archived APs'}</ButtonUpper>
						</Col>
					</Row>
					{showArchived &&
						<Row>
							<Col span={24}>
								{archivedArray.length ? (
					      			 <CustomCollapse
								          	bordered={false}
								          	ghost={true}
								          	expandIcon={(props: any) => <Link to={envs.routeSuffix + '/' + NavItem.CLIENT_PROFILES + '/step' + nav.nav.step + '/' + props.panelKey}><ButtonUpper btnType="default">Edit <RightOutlined /></ButtonUpper></Link>}
								          	expandIconPosition='right'
								        >
								        {archivedArray.map((person, i) => {
									        	return (
									        		<Panel key={person.id} header={(<CollapsePanel archived={person.archivedAt ? true : false} name={(person.firstName ? person.firstName : 'New') + ' ' + (person.lastName ? person.lastName : 'Person') } role={person.roleType ? (person.roleType ==='OtherIndividual' ? 'Other' : person.roleType as string) : 'Role'} />)}>
                                                        <></>
											        </Panel>
									        	)
								        })}

								    </CustomCollapse>
								) : (
									<>
										{isFullfilled ? (
											<Empty style={{'marginTop':'50px', 'marginBottom' : '30px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description='No archived UBOs' />
										) : (
											<Spin size="large" style={{'marginTop': '30px'}} />
										)}
									</>
								)}
							</Col>
						</Row>
					}
					</>
				}
        </Space>
        )

}

export default KycPersonsListingForm

